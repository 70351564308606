<template>
  <div class="df" style="padding: 20px; flex-direction: column; height: 100%">
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>电子鼻</el-breadcrumb-item>
        <el-breadcrumb-item>电子鼻分组</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="flex: 1; height: 0; padding: 20px">
      <el-table :data="tableData" style="width: 100%" height="100%">
        <el-table-column prop="groupName" label="分组" width="450">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="detailList(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="df aic jcc" style="flex-direction: row">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/util/extra-api";
export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        companyId: null,
        keyword: "",
      },
      total: 0,
      tableData: [],
    };
  },
  methods: {
    getList() {
      api.get("/v1/wx/nearInfrared/groupList", this.query).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    detailList(row) {
      this.$router.push({
        path: "/admin/nose-list",
        query: {
          groupName: row.groupName,
        },
      });
    },
    pageChange() {},
  },
  mounted() {
    this.query.companyId = localStorage.getItem("company_id");
    this.getList();
    // [
    //   {
    //     name: "resistance0",
    //     data: [34567, 45678, xxxx],
    //   },
    //   {
    //     name: "resistance1",
    //     data: [xxxx, xxxxx, xxxx],
    //   },
    // ];
  },
};
</script>

<style lang='scss' scoped >
</style>